@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;469;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* font: inherit; */
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    font-family: 'Oswald', sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/*my profile*/
.profile-inner-content{
    padding:50px 0px;
}
#simple-board{
    overflow-x: auto;
}
.profile-inner-content .react-tabs ul{
    display: unset;
    padding: 20px;

}

/* review css */
.inside-delete-content-box{
	padding: 30px;
	background-color: #eaeaea;
	display: inline-block;
	box-shadow: 0 8px 6px -6px black;
}
.delete-content-box{
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0,0,0,0.5);
	top:0;
	left:0;
	width: 100%;
	height: 100vh;
    opacity: 0;
    z-index: -1;
	padding: 30px ;
	
	


} 

<!--harpreet sir -->
.Proslider a { display: inline!important; display: initial!important; }
.delta{
	opacity: 1!important;
	transition: 0.5s!important;
	z-index: 99999!important;
}
.delete-content-box .btn-opt{
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}
.delete-content-box .btn-opt button{
	color: #fff;
    background-color: #e85e00;
    border-color: #e85e00;
    box-shadow: none;
    display: inline-block;
    font-weight: 400;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    text-transform: capitalize;
    border-radius: .25rem;
    outline: none;
    border: none;
}

.errorclass {
    border: 1px solid #ff0000!important;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 0 0 transparent!important;
}
    
.delete-content-box p{
	font-size: 20px;
	font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}											
.review-content{
    display: none;
}
.custom-review .wrt-review .write-review-btn:hover{
    color:#fff;
    background: #e85e00;
    border:none;

}
.show-review-box{
    display: block;
}
.review-box h5{
    color: #373737;
    font-size: 24px;
    text-transform: capitalize;
    font-weight: normal;
    margin: 0px;
    padding: 0px 0px 15px 0px;
    font-family: Arial, Helvetica, sans-serif;
}
.review-box .rate{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.review-box .rate span{
    margin:0px 20px 0px 0px;
}
ul li .review-box .rate p{
    margin-bottom: 0px;
}
ul li .review-box .rate p svg{
    color:#dddddd;
}
.review-box  form fieldset{
    margin-bottom: 15px;
}
.review-box  form fieldset label{
    font-size: 15px;
    text-transform: capitalize;
    color: #333333;
    margin-bottom: 5px;
}
.review-box  form fieldset textarea{
    display: block;
    background-color: #fff;
    border: 1px solid #ced4da;
    line-height: 28.8px;
    outline: medium none;
    padding: 5px 15px;
    transition: all 0.4s ease 0s;
    width: 100%;
    height: 100px;
    border-radius: 6px;
}
.review-box  form button{
    color: #fff;
    background: #e85e00;
    border-radius: 30px;
    padding: 10px 25px;
    border: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    outline: none;
}

/* review css */
.profile-inner-content .react-tabs ul li{
    display: block;
    /* text-transform: capitalize; */
    margin-bottom:10px;
    padding:10px;

}

.table-heading-box .table-title-box .title-line{
    display: flex;
    justify-content: space-between;
    background-color: #373737;
    padding: 10px;

}
.right-details-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.img-zoomer img{
    width: 100%;
    height: 100%;
}

.right-details-box h3{
    font-size: 30px;
    text-transform: capitalize;
    font-weight: 600;
}
.profile-details-box .right-details-box ul .edit-btn button{
    background-color: #d46523;
    color: #fff;
    font-weight: 600;
    border: none;
    padding: 8px 20px;
    text-transform: capitalize;
    border-radius: 5px;
    display: inline-block;
    outline: none;
}
.table-heading-box .table-title-box .title-line .status{
    text-align: right;
}
.table-heading-box .table-title-box .title-content .name{
    text-transform: capitalize;
}
.table-heading-box .table-title-box .title-content .payment{
    text-transform: uppercase;
}

.error h3{
    font-size:70px;
    font-family:Oswald;
    color:#373737;

}

.About h3{
    font-size: 30px;
    font-weight: bold;
    color: #373737;
    font-family: Arial, Helvetica, sans-serif;
    padding: 15px 0px;
}
.content-line{
    margin-top: 10px;
}
.terms-condition{
    margin-bottom: 50px;
}
.terms-condition p{
    font-size: 22px;
    font-weight: bold;
    color: #373737;
    font-family: Arial, Helvetica, sans-serif;
}
.terms-condition ul{
    padding:10px 0px;
}
.terms-condition .last-line-condition{
    color: #007bff;
}
.terms-condition a{
    text-decoration: none;
}
.terms-condition ul li{
    font-size: 16px ;
    font-weight: normal;
    line-height: 26px;
    text-align: justify;
    color: #565656;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
}
.terms-condition ul li a{
    text-decoration: none;
}
.terms-condition ul li span{
    color: #373737;
    font-size: 20px;
    display: inline-block;
    margin-right: 10px;

}
.terms-condition ul .term-dot-line{
    margin-bottom: 0px;
}
.terms-condition ul li p{
    text-transform: capitalize;
    margin-bottom: 10px;
}
.table-heading-box .table-title-box .title-content .order-id{
    color: blue
}
.table-heading-box .table-title-box .title-content .order-id a{
    text-decoration:none;
}
.profile-inner-content .react-tabs ul #react-tabs-8 a{
    text-decoration: none;
    color: #000;
    
}
.tab-right-content .login-form{
    width: 100%!important;
}
.table-title-box{
    background-color: #fff;
}
.tab-right-content .login-form .form-title,.tab-right-content .login-form #register{
    width: 50%;
}
.table-heading-box .table-title-box .title-content li .select-it .one{
    background-color: #FFFF00;
}
.table-heading-box .table-title-box ul{
    display: flex;
    padding: 10px;
}
.table-heading-box .table-title-box ul li{
    width: 112px;
    font-size: 14px;  
}
.table-heading-box .table-title-box ul li select{
    width: 100%;
    margin: 0 10px;
    background: transparent none repeat scroll 0 0;
    border: 1px solid #d3d3d3;
    color: #777;
    font-size: 14px;
    margin: 0 5px;
    padding: 5px 12px;
    vertical-align: middle;
    text-transform: capitalize;
    outline: none;
    border-radius: 5px;
}
/*blog*/
    .blog-section{
        margin: 30px 0px;
    }
    .blog-section .inner-blog-box{
        display: flex;
        justify-content: space-between;
    }
    .left-inner-box .below-blog-content{
        padding:0px 15px;
    }
    .left-inner-box .below-blog-content h3{
                font-size: 30px;
                font-weight: bold;
                color: #373737;
                font-family: Arial, Helvetica, sans-serif;
                padding-bottom: 14px;
                text-transform: capitalize;
    }
    .left-inner-box .below-blog-content p{
        color: #737373;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    margin-bottom: 5px;
    }
    
    .left-inner-box{
        
        margin-right: 30px;
    }
    .left-inner-box .inside-blog-content{
        display: flex;
        
    }
    .left-inner-box .inside-blog-content .blog-img{
        width: 540px;
        height: 405px;
        padding: 15px;
    }
    .left-inner-box .inside-blog-content .blog-details{
        width: 36%;
        padding: 15px;
    }
    .left-inner-box .inside-blog-content .blog-details ul li{
        padding-bottom: 5px;
        margin-bottom: 15px;
        border-bottom: 1px solid #eee;
    }
    .left-inner-box .inside-blog-content .blog-details ul li label{
            color: #000;
            display: block;
            font-family: Oswald;
            font-size: 16px;
            margin-bottom: 5px;
            font-style: normal;
            text-transform: capitalize;
            line-height: 15px;
            text-decoration: none;
    }
     .left-inner-box .inside-blog-content .blog-details ul li p{
        color: #737373;
        font-size: 14px;
        text-transform: capitalize;
        margin-bottom: 5px;
     }
    .left-inner-box .inside-blog-content .blog-img img{
        width: 100%;
        max-width: 100%;
        max-height:100%;
        object-fit: cover;
    }
    .blog-section .inner-blog-box .recent-inner-box .recent-blog{
        padding: 10px;
         box-shadow: 0 0px 5px #777;
         border-radius: 5px;
         width: 230px;
         margin-bottom: 30px;
    }
    .About .blog-section .inner-blog-box .recent-inner-box .recent-blog-b{
        margin-bottom: 0px;
    }
    .blog-section .inner-blog-box .recent-inner-box .recent-blog ul li a{
        color: #000;
        text-decoration: none;
    }
    .blog-section .inner-blog-box .recent-inner-box .recent-blog ul li h3{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        font-family: Oswald;
        line-height: 18px;
    }
    .blog-section .inner-blog-box .recent-inner-box .recent-blog ul li h2{
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .blog-section .inner-blog-box .recent-inner-box .recent-blog h4{
        text-transform: capitalize;
        font-size: 24px;
    }
    .blog-section .inner-blog-box .recent-inner-box .recent-blog ul li p{
        font-size: 12px;
        color: #737373;
        margin-bottom: 15px;
    }
/**/
.table-heading-box{
    background-color: #fff;
    margin-top: 30px;
    overflow-x:auto;
}
.table-heading-box .table-title-box .title-line div{
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 15px;
    flex:1 1;
   
}

/*my profile*/
.tab-right-content h2{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}
.date-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.date-content div label{
    display: block;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}
.date-content div input{
    padding: 10px 15px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border:none;
}
.date-content div button{
    background-color: #d46523;
    color: #fff;
    font-weight: 600;
    border: none;
    padding: 8px 20px;
    text-transform: capitalize;
    border-radius: 5px;
    display: inline-block;
    margin-top: 20px;
    outline:none;
}
.tab-left-content .dashboard-content h2{
    color: #fff;
    text-transform: capitalize;
    margin-top: 10px;
}
.tab-left-content .dashboard-content p{
    color: #fff;
    margin-top: 5px;
}
.tab-left-content .dashboard-content{
    background-color: #d46523;
    text-align: center;
    padding: 30px 0px;
    border-radius: 10px;
}
.tab-left-content .dashboard-content img{
    width: 75px;
    height: 75px;
    border-radius: 50%;
}
.date-content div input::-webkit-input-placeholder{
    font-size: 14px;
}
.date-content div input:-ms-input-placeholder{
    font-size: 14px;
}
.date-content div input::-ms-input-placeholder{
    font-size: 14px;
}
.date-content div input::placeholder{
    font-size: 14px;
}
.tab-right-content{
    background-color: #fafafa;
    padding: 20px;
    height: 100%;
}
.profile-details-box{
    display: flex;
}
.left-details-box{
    flex: 1 1;
}
.left-details-box img{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.right-details-box{
    flex:2 1;
}
.profile-details-box .right-details-box ul{
    padding: 0px;
    width: 100%;
}
.profile-details-box .right-details-box ul li{
    margin-bottom: 20px;
}
.profile-details-box .right-details-box ul li h3{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
}
.profile-details-box .right-details-box ul li input{
    border:none;
    outline: none;
    padding:10px;
    color: #373737;
    font-size: 14px;
    opacity: .8;
    width: 250px;
    text-transform: capitalize;
    border:1px solid #eee;
}
.profile-details-box .right-details-box ul li input[type="submit"]{
    display: inline-block;
    margin-top: 20px;
    padding: 14px 40px;
    font-size: 16px;
    border-radius: 45px;
    line-height: 0em;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none!important;
    color: white !important;
    background: #d4651f !important;   
}
.order{
    width: 100%;
}
.left-details-box span{

    display: inline-block;
    padding:5px;
    width: 85%;
    margin-top: 10px;
    background-color: #fff;
}
.left-details-box input{
    
    
} 
/*my profile*/
/*invoice css*/
    .Invoice{
        width: 50%;
        padding:100px 0px;
        margin: auto;
    }
    .invoice-header{
        background-color: #000;
        display: flex;
    }
    .invoice-header .left-invoice-box{
        flex:1 1;
    }
    .invoice-header .right-invoice-box{
        display: flex;
        flex: 1 1;
        align-items: center;
        justify-content: space-around;
    }
    .invoice-header .right-invoice-box h4{
        font-size: 16px;
        color: #fff;
        font-weight: 600px;
        margin-bottom: 5px;
        text-transform: capitalize;
    }
    .invoice-header .right-invoice-box p{
        font-size: 14px;
        color: #fff;
        text-transform: capitalize;

    }
    .address-content{
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;

    }
    .left-address-box h5{
        margin-left: 10px;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .left-address-box .inside-left-box{
        border:1px solid #eee;
        margin: 5px 10px;
        padding: 10px;
        height: 150px;
    }
    .inside-left-box p{
        text-transform: capitalize;
    }
    .inside-left-box .add{
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .inside-left-box span{
        font-size: 15px;
        text-transform: capitalize;
    }
    .inside-left-box h6{
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        background-color: #ececec;
        padding: 5px 10px;
        margin-bottom: 10px;
    }
    .price-table{
        background-color: #ececec;
        text-align: center;
        height: 40px;
    }
    .price-content{
        padding: 0 10px;
        margin-bottom: 30px;
        overflow-x:auto;
    }
    .price-content table{
        
    }
    
    .price-table th{
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
       
    }
            table, td, th {
          border: 1px solid #eee;
          vertical-align: middle;
          text-transform: capitalize;
        }
        table tr{
            text-align: center;

        }
        .price-amount{
            height: 30px;
        }
        table {
          border-collapse: collapse;
          width: 100%;
        }
      

        .decalration{
            background-color:  #d46630;
            padding:10px;
            margin: 0 10px;
            margin-bottom: 30px;

        }
        .decalration h5{
            color:#fff;
            font-weight: 600;
            text-transform: capitalize;
            text-align: center;
        }
        .decalration p{
            color:#fff;
            font-weight: 600;
            font-size: 13px;
            text-transform: capitalize;
            text-align: center;
        }
        .invoice-content{
            border:1px solid #eee;
        }
                
/*invoice css*/
.table-heading-box table {
  border-collapse: collapse;
  border:none;
  width: 100%;
}
.table-heading-box table th,.table-heading-box table td{
    border:none;
    border-radius: 0px!important;
}
.table-heading-box table .price-amount td a{
    text-decoration: none;
}
.table-heading-box table .price-amount td{
    font-size: 14px;
    
}
.table-heading-box table .price-amount td:nth-child(6){
    text-transform: uppercase;
}
.table-heading-box table .price-table{
    background-color: #373737!important;
}
.table-heading-box table .price-table th{
    text-transform: uppercase;
    color: #fff;
}
.table-heading-box th, td {
  text-align: center;
  padding: 4px;
  text-transform: capitalize;
}
.table-heading-box th, td select{
    border-radius: 3px;
}

.table-heading-box tr:nth-child(odd) {background-color: #f2f2f2;}
/**/

figure {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
}
.react-tabs__tab-list .react-tabs__tab img{
    width:150px;
    height: 100px;
}
figure img{
    max-width: 100%;
    width:100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
}
figure:hover img {
  opacity: 0;
}
.sub-item-listing{
    margin-top: 30px;
}
.zoom-img img {
  display: block;
  pointer-events: none;
}
/**/
.icon-list svg {
    color: #fff;
}
.test{
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
    width: 255px;
    z-index: 9;
   
}
.test2{
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
    z-index: 9;
    
}
.test-remove{
    position: unset!important;

}

#register{
    padding: 20px 0px;
}
#register label{
    text-transform: capitalize;
    display: block;
    margin-bottom: 5px;
    color: #373737;
}
#register button{
    background-color: #d46523;
    color: #fff;
    font-weight: 600;
    border: none;
    padding: 8px 20px;
    text-transform: capitalize;
    border-radius: 5px;
    display: inline-block;
    outline: none;
}

/**/

.react-tabs__tab-panel  h2 div {
  width:   100%!important;
  height: 400px!important;
  
}
.Collection,.About,.Contact,.Product,.Profile,.Invoice{
    margin-top: 95px;
}

.overlay2{
    height: 98%;
    width: 98%;
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: 0px 0px 8px #000;
}
.overlay2 .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: #8c8989;
}
.overlay2 input[type=text] {
    padding: 15px;
    font-size: 17px;
    border: none;
    float: left;
    width: 79%;
    background: white;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    height: 50px;
    outline: none;
}
.overlay2 button {
    border-radius: 6px;
    float: left;
    width: 20%;
    padding: 15px 10px;
    background: #ce9634;
    font-size: 17px;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 1%;
}
.overlay-content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.overlay-content form{
    width: 80%;
    position: relative;
}
.overlay1{
    display: block;
}
.overlay3{
    display: none!important;
}
figure:hover img {
  opacity: 0;
}

figure img {
  display: block;
  margin: 0 auto;
  pointer-events: none;
}
.Header{
        background-color: #000;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 345;
}
form input{
    background-color: #fff;
    border: 1px solid #ced4da;
    line-height: 28.8px;
    outline: medium none;
    padding: 5px 15px;
    transition: all 0.4s ease 0s;
    width: 100%;
    height: 34px;;
    border-radius: 6px;

}


.product-slider .slick-slider .slick-list{
    max-height: 210px;
    overflow: hidden;
}


.nav-bar ul .hovvy .show-now{
    display: block;
}
.nav-bar ul .hovvy1 .show-now{
    display: block;
}
.nav-bar{
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.Header .header-icon .icon-list{
margin-right: 25px;
}

.errorMsg {
  color: #cc0000;
  font-size: 13px;
  padding-top: 5px;
}
.product-b-icon p{
    text-align: center;
}
.sucessMsg {
  color: #6B8E23;
  margin-bottom: 10px;
}
.nav-bar ul ,.header-icon{
    margin: 0;
    list-style: none;
}
.header-icon  li a svg{
    color: #fff;
}
.show-bar{
    left: 0%!important;
    transition: 0.5s;
}
.product-b-icon .pin-it svg{
    color: #fff;
    background-color: red;
    margin: 0 5px; 
    font-size: 20px;
}
.product-b-icon .tweet svg{
    color: #00aced;
    margin: 0 5px; 
    font-size: 20px;
}
.product-b-icon p span{
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    white-space: normal;
    font-size: 11px;
}
.cart-form table{
    width: 100%;
}
#qty{
    width: 30px;
    text-align: center;
}
.qty2 input{
    width: 30px;
    background-color: #fff;
    border:1px solid #dddddd;
    font-size: 14px;
    color: #373737;
   

}
.horizontal-content{
    vertical-align: middle;
}
.cart-form  th{
    
    color: #212529;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
}
.cart-form  .side-content  img{
    width: 50px;
   
}
.cart-form  td, .cart-form  th {
  border: 1px solid #ddd;
  padding: 15px;
  
}
.etabs ul{
    height: 60px;

}
.etabs li{
        background: #e85e00 none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    outline: medium none;
    padding: 20px 20px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    font-weight: bold;
}
.sop-cart{
        color: #393939;
    font-size: 14px;
}
#title h2{
    border-bottom: none!important;
}
.tb-tab-container2{
    display: flex;
    border:1px solid #dddddd;
    justify-content: space-between;
}
.checkout-coupon input{
    border-radius: 30px;
    margin: 10px auto;
    padding: 8px 27px;
    font-family: Arial, Helvetica, sans-serif;
    background: #fff none repeat scroll 0 0;
    border: 1px solid #c5c5c5;
    color: #373737;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    width: 65%;
}
.form-row-wide label{
        display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}
.another-cen{
    padding: 30px;
    border-left: 1px solid #dddddd;
}
.last-check1 .checkout-coupon {
    text-align: right;
    font-weight: 900;
    margin-top: 50px;
    margin-right: 50px;
}
.sub-total{
    border:1px solid #dddddd;
}
.order-total .amount{
    color: #363636;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    font-weight: bolder;
}
.pull-right{
    margin-left: 50px;
}
.wc-proceed-to-checkout{
    display: flex;
    margin-top: 20px;
}
.tb-tab-container2 ul{
    height: 60px;
}
.wc-proceed-to-checkout .b1{
    background-color: #e86135;
    color: #ffffff!important;
    border:none!important;
}
.pull-right .wc-proceed-to-checkout a{
    ackground-color: transparent;
    font-size: 14px;
    border: 1px solid #c5c5c5;
    border-radius: 30px;
    color: #373737;
    display: inline-block;
    font-weight: bold;
    outline: medium none;
    padding: 8px 15px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    transition: all .4s ease 0s;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
}
.cart-subtotal th{
    color: #747474;
    font-size: 16px;
    font-weight: normal;
    text-transform: capitalize;
}
.order-total th{
    color: #363636;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    font-weight: bolder;
}
.cart-subtotal{
    border-bottom: 1px solid #dddddd;
    padding: 15px 0px;
}
.sub-total table tr th{
    padding: 15px 0px;
}
.sub-total table{
    width: 100%;
    text-align: center;
}
.amount{
    color: #747474;
    font-size: 20px;
}
.product-b-icon  p{
    text-align: center!important;
}
.product-b-icon .fb svg{
    color: #fff;
    background-color: #3b5997;
    margin: 0 5px; 
    font-size: 20px;
}
.right-box video{
    height: 100%;
}
.nav-bar ul li:hover{
    background-color: #252525;
}
.nav-bar ul li:hover a{
    color: #d46630;
}
.category .mega-menu .tas1 .tas a:hover{
    transform:translate(10px,0px);
    color: #d46630;
    transition: 0.5s;
}

.show-now2 { display: none!important; }



.lalas-map{
    width:100%;
    height: 400px;
}
.testimonial-section .slick-slider .slick-dots{
    display: none!important;
}
#video_player{
    outline: none;
}
/* new update*/
.outer-quick-content .quick-content .pro-details{
    font-size: 22px;
    font-weight: 900;
    text-transform: capitalize;
    color: #e86135;
    text-decoration: none;
    display: inline-block;
    margin-top: 30px;

}

.outer-quick-content{
    position: fixed;
    background-color: rgba(0,0,0,0.2); 
    width: 100%;
    z-index: 9999;
    display: none;
    padding: 30px 0px;
    left: 0;
    top: 0;
    height: 100%;
    overflow: scroll;
    
}

.show-quick-box{
    height: 100vh;
   }
.show-quick-content{
    display: block;
}

.product-b-icon p span:nth-child(2),.product-b-icon p span:nth-child(4){
    margin-right: 20px;
}
.quick-content{
    background-color: #fff;
    padding: 40px;
    position: relative;
    
}

.quick-content .quick-view1{
    position: absolute;
    right: calc(0% + 15px);
    top: calc(0% + 15px);
    font-size: 30px;
    color: #909090;
}


.arrow-sign{
    transform: rotate(90deg)!important;
}



.frame {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  display: none;
  background: white;
  transform: translateZ(0);
}

/* new update*/
.nav-bar ul li{
    padding: 35px 0px;
    position: relative;
}
.nav-bar ul li:hover{
    background-color: 
}
.nav-bar ul li a{
    color: #fff;
    display: inline-block;
    font-family: Oswald;
    font-size: 14px;
    font-style: normal;
    padding: 5px 20px;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    line-height: 15px;
    text-decoration: none;
} 
.nav-bar ul li a img{
    width: 100%;
}
/*
*/
.inner-product-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inner-product-content .s-title{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #373737;
    font-weight: bold;
    margin: 0px;
    padding: 5px 0px;
    text-transform: capitalize;
}

.inner-product-content .g-title{
    color: #747474;
    display: inline-block;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: normal;
}

.Cate{
    padding: 0;
    margin: 0;
    border:1px solid #ececec;
    background-color: #fafafa;
}
.tab-content-scroll{
    width: 630px;
    border: unset;
    background-color: unset;
    
}
.tab-content-scroll figure{
    height: 370px;
}
.inner-category-list{
    display: block!important;
    transition: 0.5s ease-in-out;
}
.inner-product-content .g-title label{
    margin-right: 20px;
}
.inner-cate ul li div span{
    padding: 10px;
}
.inner-cate ul li{
    
    text-decoration: none;
}
.category-list .innerlist-content a{
    padding: 0;
    margin-bottom: 10px;
    color: #909090;
    display: block;
    font-size: 13px;
    line-height: normal;
}
.inner-cate ul li ul{
    padding: 0 15px;
    display: none;
}
.inner-cate ul li ul li a{
        color: #909090;
    display: block;
    font-size: 13px;
    line-height: normal;
    padding: 0.5em 1em 0.5em 1.5em;
}
.inner-cate ul li ul li a:hover{
    color: #e85e00;
}
.inner-product-content .g-title {
    text-transform: uppercase;
}
.inner-cate ul li a{
    color: #3f3f3f;
    text-transform: capitalize;
    font-size: 14px;
    width: 100%;
    line-height: 18px;
    text-decoration: none;
    padding: 10px 10px;
}
.Product .design-area .inner-cate ul li{
        display: block;
}
.inner-cate ul li .category-list  .cat-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner-cate ul li .category-list  .cat-content:hover a,.inner-cate ul li .category-list  .cat-content:hover span{
    color: #e85e00;
}
.inner-cate ul li a span{
    float: right;
}
.inner-cate h2{
    color: #d9d9d9;
    background: #000;
    padding: 12px 12px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 18px;
}

/* product-details*/
/* .react-tabs .react-tabs__tab-panel h2{
    width: 100%;
    height: 100%;
} */
.outer-quick-content .quick-content .star-box  .p-price{
    font-size: 30px!important;
    font-weight: bold!important;
    color: #e85e00!important;
    margin: 10px 0px;
}
.outer-quick-content .quick-content .star-box .stock-title{
    text-transform: capitalize;
    font-weight: normal;
    font-size: 16px;
    color: #5f5f5f;
    margin-right: 2px;
}
.outer-quick-content .quick-content .star-box .q-title p{
    display: inline-block;
    border:1px solid #909090;
    line-height: 30px;
} 
.outer-quick-content .quick-content .star-box .q-title p span{
    margin: 0;
    color: #909090;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
}
.outer-quick-content .quick-content .star-box .q-title p span:nth-child(1),.outer-quick-content .quick-content .star-box .q-title p span:nth-child(2){
    border-right:  1px solid #909090;
}
.outer-quick-content .quick-content .star-box .q-title{
    font-weight: normal;
    font-size: 16px;
    color: #5f5f5f;
    margin-right: 2px;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 40px;
}
.react-tabs .react-tabs__tab-panel h2 img{
    width: 100%;
    height: 100%;
}
.star-box .star-icon{
    margin-bottom: 0px!important;
}
.star-box p svg{
    color: #e75e35;
    font-size: 25px;
}
.star-box h2{
    font-size: 27px;
    font-family: Arial, Helvetica, sans-serif;
    color: #373737;
    font-weight: bold;
    line-height: 30px;
    padding: 20px 0px 15px 0px;
    text-transform: capitalize;
}
.newsletter-email{
    color: #fff;
}
.design-area .star-box .p-price{
        font-size: 30px!important;
    font-weight: bold!important;
    color: #e85e00!important;
    margin: 20px 0px;
}
.design-area .star-box .stock-title{
    text-transform: capitalize;
    font-weight: normal;
    font-size: 16px;
    color: #5f5f5f;
    margin-right: 2px;
}
.custom-review div ul li{
    border-top: 1px solid #cccccc;
    margin-top: 30px;
    padding-top: 20px; 
}

.tit{
    font-weight: 900;
}
.custom-review div ul li span{
        color: #585858;
        display:inline-block!important;
        text-transform: capitalize;
    display: inline;
    font-size: 15px;
    line-height: 24px;
    margin: 10px 0px;
}

.design-area .star-box .stock-title span{
    color: #e85e00;
}
.design-area .star-box .q-title{
    text-transform: capitalize;
    font-weight: normal;
    font-size: 16px;
    color: #5f5f5f;
    margin-right: 2px;
}
.design-area .star-box .q-title p{
    display: inline-block;
}
.design-area .star-box .q-title p span{
    border:1px solid #ececec;
    margin: 0!important;
    width: 50px;
    font-size: 25px;
    font-weight: 300;
     color: #5f5f5f;
    padding: 10px 5px;
}
.star-box p span{
        color: #373737;
    font-weight: bold;
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin: 3px 10px 0px 10px;
}
.descr{
    padding-top: 50px;
}
.star-box .descr h2{
   color: #373737;
    font-size: 24px;
    font-weight: normal;
    margin: 0px;
    padding: 0px 0px 15px 0px; 
}
.wrt-review{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wrt-review div:nth-child(1) p svg{
    font-size: 20px;
}
.wrt-review div:nth-child(1) p{
    color: #373737;
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    margin: 3px 0px;
    text-transform: capitalize;
}
.wrt-review div:nth-child(2) a{
    background-color: transparent;
    font-size: 14px;
    text-decoration: none;
    border: 1px solid #c5c5c5;
    border-radius: 30px;
    color: #373737;
    display: inline-block;
    font-weight: bold;
    outline: medium none;
    padding: 8px 30px;
    text-align: center;
    text-transform: uppercase;
}
.star-box .descr p{
    color: #707070;
    font-size: 14px!important;
    font-weight: normal;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: 24px;
    text-align: justify;
}
.custom-review h3{
        color: #373737;
    font-size: 24px;
    font-weight: normal;
    margin: 0px;
    padding: 0px 0px 15px 0px;
    text-transform: capitalize;
}

.buy-btn a:nth-child(1){
    background-color: #e86135;
    font-size: 14px;
    border: 1px solid #c5c5c5;
    border-radius: 30px;
    color: #ffffff;
    margin:10px;
    text-decoration: none;
    display: inline-block;
    font-weight: bold;
    outline: medium none;
    padding: 10px 30px;
    text-align: center;
    line-height: 25px;
    text-transform: uppercase;
}
.buy-btn a:nth-child(2){
    background-color: transparent;
    font-size: 14px;
    text-decoration: none;
    border: 1px solid #c5c5c5;
    border-radius: 30px;
    color: #373737;
    line-height: 25px;
    display: inline-block;
    font-weight: bold;
    margin: 10px;
    outline: medium none;
    padding: 10px 30px;
    text-align: center;
    text-transform: uppercase;
}

.react-tabs__tab-list{
    /* display: flex; */
}
.react-tabs__tab-list li{
    /* flex:1; */
	display:inline-block; width:24%; margin-right:1%;border: 1px solid #e4e4e4;
}
.react-tabs__tab-list .react-tabs__tab img{
    width: 100%;
}
.react-tabs__tab--selected{
    border:none!important;
}
.react-tabs__tab{
    padding: 10px 0px;
}
.inner-productss{
    display: flex;
}
.react-tabs__tab-list{
    border:none!important;
}
.inner-product p{
    text-align: center!important;
    
}

/* Support for rtl text, explicit support for Arabic and Hebrew */


/**/
.nav-bar ul li:nth-child(2){
    position: relative;
}
.nav-bar ul li:nth-child(2):before{
    content:"";
    background-image: url(/static/media/angle-down-solid.e0e03861.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 15px;
    height: 15px;
    position: absolute;
    right: calc(0% + 5px);
    top:calc(50% - 6.5px);
    color: transparent;
    
}
.footer-top{
    background-color: #212121;
    padding: 50px 0px;
}
.footer-bottom{
    background-color: #161616;
    padding: 30px 0px;
}
.footer-top .wg-title{
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 9px;
    text-transform: uppercase;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    line-height: 29px;
    opacity: 1;
    transition: opacity 0.24s ease-in-out 0s;
    visibility: visible;
}
.footer-top ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.first-footer .company,.first-footer .company p{
    font-weight: 500;
    color: #ddd;
    font-size: 15px;
}
.first-footer ul li a{
    transition: all 0.4s ease 0s;
        color: #ddd;
            text-decoration: none;
            font-size: 12px;
            line-height: 20px;
}
.textwidget ul li .footer-first-icon{
    border: 1px solid #727272;
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
}
.textwidget ul li .footer-first-icon svg{
    font-size: 15px;
    color: #fff;
}
.textwidget ul li{
    display: flex;
    align-items:  center;
    margin-bottom: 10px;

}
.textwidget ul li p{
    flex: 1 1;
}
.textwidget ul li p, .textwidget ul li .footer-no{
    transition: all 0.4s ease 0s;
    color: #ddd;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
}
.newsletter form{
    display: flex;
    background-color: #121212;
    border-radius: 30px;
    margin-bottom: 40px;

}
.newsletter p{
    margin-bottom: 0;
}
.newsletter form input{
    background-color:transparent;
    width:100%;
    height: 100%;
    border:none;
    padding: 0 15px;
}
.newsletter input[type="submit"]{
    background: #494949 none repeat scroll 0 0 padding-box;
    border-radius: 0 30px 30px 0;
    color: #ffffff;
    font-size: 12px;
    line-height: 35px;
    text-transform: uppercase;
}
.newsletter form input::-webkit-input-placeholder{
    color: #494949;
    font-size: 12px;
}
.newsletter form input:-ms-input-placeholder{
    color: #494949;
    font-size: 12px;
}
.newsletter form input::-ms-input-placeholder{
    color: #494949;
    font-size: 12px;
}
.newsletter form input::placeholder{
    color: #494949;
    font-size: 12px;
}
.socials{
    display: flex;
}
.socials li a span{
    border: 1px solid #727272;
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
}
.socials li a span svg{
    font-size:15px;
    color: #fff;
}
.foot-icon ul {
    list-style: none;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-around;
}
.footer-address address{
    transition: all 0.4s ease 0s;
    color: #ddd;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px;
}
address a{
    transition: all 0.4s ease 0s;
    color: #ce9634;
    text-decoration: none;
    font-size: 12px;
    line-height: 20px;
}
.foot-icon ul li {
    float: right;
}
.header-icon{
    padding: 35px 0px;
}
.header-icon li:nth-child(3){
    position: relative;
}
.con-text a{
    text-decoration: none!important;
}
.header-icon li:nth-child(3) span{
    position: absolute;
    background-color: #fff;
    color: #000;
    font-size: 10px;
    display: inline-block;
    width: 15px;
    text-align: center;
    right:-8px;
    z-index: 23;
    line-height:15px;
    border-radius: 50%;
}
.contact-img-area{
    position: relative;
    background: rgba(0, 0, 0, 0.9) none repeat scroll 0 0;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.contact-img-area:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(/static/media/bg-pagetitle.aa492f0f.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    right: 0;
    top: 0;
}
.contact-img-area .page-title{
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: Oswald;
    font-size: 40px;
    font-style: normal;
    font-weight: normal;
    line-height: 60px;
    opacity: 1;
}
.text-center .con-text p a{
    display: inline-block;
    color: #fff;
    font-size: 14px;
    padding-right: 30px;
    position: relative;
    text-shadow: 0px 0px #000000;
}
.text-center .con-text p a:before{
    content:"";
    background-image: url(/static/media/angle-down-solid.e0e03861.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 10%;
    top:calc(50% - 7.5px);
    color: transparent;
    transform: rotate(-90deg);
}
.text-center .con-text p{
    color: #e85e00;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    margin: 5px 0 0;
    opacity: 1;
    text-shadow: 2px 2px 0px #000000;
    transition: opacity 0.24s ease-in-out 0s;
    visibility: visible;
}
.design-area{
    padding:50px 0px;
}
.about{
    margin-top: 20px;
}
#register form label{
    color: #666;
    text-transform: capitalize;
}
#register form button{
    background-color: #d46523;
    border: 1px solid #d46523;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    line-height: 1;
    margin-top: 10px;
   font-weight: bold;
    padding: 13px 45px;
    text-align: center;
    text-transform: uppercase;
    float: right;
    font-family: Arial, Helvetica, sans-serif;
}
#register form .home-log-btn{
    background-color: #d46523;
    border: 1px solid #d46523;
    border-radius: 30px;
    color: #fff!important;
    display: inline-block;
    line-height: 1;
   font-weight: bold;
    padding: 13px 45px;
    text-align: center;
    text-transform: uppercase;

    font-family: Arial, Helvetica, sans-serif;
}
#register form textarea{
    display: block;
    height: 120px;
    border: 1px solid #ced4da;
    padding: 5px 15px;
    width: 100%;
    border-radius: 6px;
}
.main-registration-container{
    width: 100%;
}
.design-area .about-title h1{
    font-size: 30px;
    font-weight: bold;
    color: #373737;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 14px;
}
.design-area p{
    font-size: 16px !important;
    font-weight: normal;
    line-height: 26px;
    text-align: justify;
    color: #565656;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
}
.about h1{
    font-size: 30px;
    font-weight: bold;
    color: #373737;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 14px;
}
.contctinfo h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    color: #545454;
    margin-bottom: 5px;
}
.contctinfo h2{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    color: #d4651f;
}
.contctinfo ul {
    padding:0;
}
.contctinfo ul li{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.contctinfo ul li .footer-first-icon{
     border: 1px solid #727272;
    height: 33px;
    width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
}
.contctinfo ul li .footer-first-icon svg{
    color: #727272;
}
.address-foot p{
    margin: 0;
    color: #747474;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px!important;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
}
.contctinfo ul li .footer-no,.contctinfo ul li .footer-no a{
    color: #747474;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px!important;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
}
.design-area form,.design-area .all-contact{
    width: 100%;
}
.design-area .all-contact .di-na label{
    color: #747474;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px!important;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 3px;
} 

.design-area .all-contact .di-na textarea{
    height: 140px;
}
.last-cart-con {
    text-align: right;
}
.last-cart-con .wpcf7{
        background-color: #d46523;
    border: 1px solid #d46523;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    line-height: 2px;
    margin-top: 20px;
    outline: medium none;
    padding: 20px 45px;
    text-align: center;
    text-transform: uppercase;
    width: auto;
    width: initial;
    transition: all 0.4s ease 0s;
    font-family: Arial, Helvetica, sans-serif;
}
.mega-menu .tas1,.mega-menu .tas4{
    display: flex;
    justify-content: space-between;
}
.mega-menu .tas4{
    margin-top: 30px;
}
.mega-menu .tas1 .tas .hedding-border a{
        border-top: none;
        color: #474747;
        text-transform: uppercase;
        font-weight: 400;
        font-family: Oswald;
        font-size: 14px;
        font-style: normal;
        text-decoration: none;
}
.category{
    padding: 30px 0px;
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    left: 0;
    display: none;
    position: fixed;
    top: calc(0% + 95px);
    width: 100%;

}
.category1{
    padding: 30px 0px;
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    left: 0;
    display: none;
    position: fixed;
    top: calc(0% + 95px);
    width: 100%;

}
.sorted{
    background: transparent none repeat scroll 0 0;
    border: 1px solid #d3d3d3;
    color: #777;
    font-size: 14px;
    margin: 0 5px;
    padding: 5px 12px;
    vertical-align: middle;
    text-transform: capitalize;
    outline: none;
}
.sub-category{
    transition: 0.5s;
    transform: scaleY(1);
    z-index: 23;
    opacity: 1;
}
.mega-menu .tas1 .tas a{
    border-top: 1px solid rgba(170, 170, 170, 0.2);
    color: #747474;
    display: block;
    font-family: inherit;
    font-size: 13px;
    line-height: 38px;
    padding: 0 0 0 0px;
    text-transform: inherit;
    text-decoration: none;
}
.nav-bar ul li:nth-child(2) a:hover  .nav-bar{
    display: none;
    
}
.slider,.slider1,.slider2{
    position: relative;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider img,.slider1 img,.slider2 img{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width:100%;
    max-height: 100%;
    object-fit: cover;
    z-index: -1;
}
.slider h1,.slider1 h1,.slider2 h1{
    font-family: Oswald;
    font-weight: 600;
    font-size: 60px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 14px;
}
.slider .banner-text,.slider1 .banner-text,.slider2 .banner-text{
    font-size: 33px;
    color: #dddddd;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.slider a,.slider1 a,.slider2 a{
        padding: 8px 40px;
    background: none;
    border: solid 2px #fff;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    border-radius: 52px;
    outline: none;
    transition: all .4s ease-in-out;
    white-space: nowrap;
    line-height: 1.5;
    text-decoration: none;
}
.slider .banner-subtext,.slider1 .banner-subtext,.slider2 .banner-subtext{
    font-size: 20px;
    font-weight: 400;
    color: #dddddd;
    text-transform: uppercase;
    letter-spacing: 7px;
    line-height: 28px;
    margin: 0 auto 60px auto;
}


.inner-slider{
    text-align: center;
}
.Home .slick-slider .slick-dots{
    bottom: 10px;
}
.Home{
    margin-top: 88px;
}
.App .Home .slick-slider .slick-dots li button{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 12px;
    padding: 0;
    height: 12px;
    cursor: pointer;
    color: #fff;
    border: 2px solid #fff;
    outline: none;
    background: transparent;
    border-radius: 50%;
} 
.App .Home .slick-slider .slick-dots li button:before{
    font-family: 'slick';
    font-size: 6px;
    line-height: 12px;
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    content: '•';
    text-align: center;
    opacity: 1!important;
    color: transparent!important;
    -webkit-font-smoothing: antialiased;
}
.App .Home .slick-slider .slick-dots .slick-active button{
    border:2px solid #ebaa00;
}
.App .Home .slick-slider .slick-dots .slick-active button:before{
    background-color: #ebaa00;
    border-radius: 50%;
}
.App .Home .slick-slider .slick-prev{
    background-color: #252525!important;
    left: 5px;
    width: 40px;
    height: 40px;
    z-index: 34;
}
.App .Home .slick-slider .slick-next{
    background-color: #252525!important;
    right: 5px;
    width: 40px;
    height: 40px;
}
.App .Home .slick-slider .slick-next:before{
    display: none;
}
.App .Home .slick-slider .slick-next:after{
    content:"";
    position: absolute;
    background-image:url(/static/media/angle-down-solid.e0e03861.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 10px;
    top:  calc(50% - 5px);
    right: calc(50% - 5px);
    transform: rotate(-90deg);


}
.App .Home .slick-slider .slick-prev:before{
    display: none;
}
.App .Home .slick-slider .slick-prev:after{
    content:"";
    position: absolute;
    background-image:url(/static/media/angle-down-solid.e0e03861.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 10px;
    top:  calc(50% - 5px);
    right: calc(50% - 5px);
    transform: rotate(90deg);
    

}
.Proslider{
    text-align: center;


}
.App .Home .product-slider{
    padding: 50px 0px 100px 0px;
    background-color: #f4f4f4;
}
.inner-proslider{
    width: 173px;
    height: 173px;
    margin:0 auto;
    overflow: hidden;
}

.Proslider img{
    border-radius: 50%;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    
    
}
.App .Home .product-slider .slick-slider ul{
    bottom: -60px;
    height: 30px;
    width: 70px;
    overflow: hidden;
    left: calc(50% - 35px);
}
.App .Home .product-slider .slick-slider button{
    background-color: #fff!important;
    border:1px solid #252525;
    border-radius: 50%;
    opacity: 0.8;

}
.App .Home .product-slider .slick-slider button:after{
    color: #252525;
}

.App .Home .product-slider .slick-slider .slick-next:before{
    display: none;
}
.App .Home .product-slider .slick-slider .slick-next:after{
    content:"";
    position: absolute;
    background-image:url(/static/media/angle-down-solid1.fcc55fde.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 10px;
    top:  calc(50% - 5px);
    right: calc(50% - 5px);
    transform: rotate(-90deg);


}
.App .Home .product-slider .slick-slider .slick-prev:before{
    display: none;
}
.App .Home .product-slider .slick-slider .slick-prev:after{
    content:"";
    position: absolute;
    background-image:url(/static/media/angle-down-solid1.fcc55fde.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 10px;
    top:  calc(50% - 5px);
    right: calc(50% - 5px);
     transform: rotate(90deg);
}
.product-tile{
    text-align: center;
    
}
.product-slider .slick-slider{
    margin-top: 30px;
}
.product-tile h2{
    color: #373737;
    font-family: Oswald;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-bottom:5px;
    text-transform: uppercase;
}
.App .Home .product-slider  a{
   font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: none;
    color: #373737;
    display:  inline;
    display:  initial;
    padding-top: 20px;
    text-transform: capitalize;
    transition: 0.5s ease-in-out;
    text-decoration: none;
}
.App .Home .product-slider .slick-slider .slick-dots button:before{
    border-radius: 50%;
    background-color:#bdc5c3;
    }
.App .Home .product-slider .slick-slider .slick-dots .slick-active button:before{
    background-color: #859791;
}
.latest-content{
    background-color: #f4f4f4;
    padding: 30px 0px;
}
.latest-title{
    text-align: center;
}
.latest-slider a{
    display: inline-block;
    margin: 15px 0px;
}


.lalas-return-content{
    text-align: right;
}
.lalas-return-content h4{
    font-size: 40px;
    line-height: 36px;
    color: #373737;
    font-weight: 500;
    text-transform: capitalize;
}
.lalas-return-content p{
    color: #373737;
    margin: 20px 0px;
    font-size: 16px;
    opacity: .8;
}
.lalas-return-content a{
        display: inline-block;
    margin-top: 20px;
    padding: 14px 40px;
    font-size: 16px;
    border-radius: 45px;
    line-height: 1em;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none!important;
    color: white !important;
    background: #d4651f !important;
}
.btn-link2{
    background-color: #e85e00;
    text-decoration: none;
}
.lala-return{
    padding: 50px 0px;
}
.lala-return img{
    border:1px solid #e5e5e5;
}
.shawls-section{
    display: flex;
     
}
.shawls-section h4{
    font-size: 40px;
line-height: 36px;
color: #ffffff;
font-weight: 800;
font-family: "Oswald";
-webkit-filter: drop-shadow(2.5px 4.33px 2.5px rgba(0,0,0,0.3));
        filter: drop-shadow(2.5px 4.33px 2.5px rgba(0,0,0,0.3));
text-transform: capitalize;
}
.left-content-1,.left-content-2,.left-content-3{
    position: relative;
}
.left-content-1 h4{
    position: absolute;
    left: 50px;
    bottom: 80px;
}
.left-content-2 div p,.left-content-3 div p{
    font-size: 16px;
line-height: 22px;
color: #ffffff;
margin-top: 10px;
}
.left-content-2 div{
    position: absolute;
    text-align: left;
    width: 200px;
    top: 20px;
    left: 40px;
}
.left-content-3 div{
    width: 200px;
    position: absolute;
    top: calc(50% - 45px);
    left: 40px;
}
.left-content-3 div h4 span{
    color: #ff8502;
}
.product-section{
    padding-top: 30px;
    padding-bottom: 10px;
}
.product-box p{
    font-size: 16px;
line-height: 36px;
color: #373737;
}
.product-box a{
    font-size: 16px;
line-height: 36px;
color: #3f3f3f;
text-transform: capitalize;

text-decoration: none;
}
.product-box{
    margin-top: 30px;
}
.inner-product p{
    color: #747474;
    font-size: 16px;
    line-height: 17px;
    text-transform: none;
    transition: all 0.4s ease 0s;
    font-weight: 400;
    margin-top: 15px;
    text-transform: capitalize;
}
.inner-product{
    text-align: center;
    position: relative;
}
.inner-product:hover .quick-view{
    opacity: 1;
    transform:translateX(-50%) translateY(-50%) scale(1);
    transition: all 0.4s ease 0s;
}
.product-section{
    padding: 50px 0px;
}
.inner-product .price{
        color: #373737;
    font-size: 16px;
    line-height: 12px;
    display: inline-block;
    margin: 15px 0px 10px 0px;
    
    text-decoration: none;
}
.inner-product .quick-view{
    background: #d4651f;
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    left: 50%;
    padding: 0px 20px;
    position: absolute;
    text-align: center;
    top: 42%;
    opacity: 0;
    text-decoration: none;
    transform: translateX(-50%) translateY(-50%) scale(0.6);
    
}
.lalas-video{
    display: flex;
    background-color: #e85e00;
}
.left-box{
    text-align: right;
    padding: 0 50px;
    width: 50%;
    background-color: #e85e00;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.left-box div p {
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin: 10px 0px;
}
.left-box div h3{
    font-size: 30px;
    line-height: 35px;
    color: #ffffff;
    font-weight: 500;
}
.latest-title p{
    font-size: 16px;
    line-height: 36px;
    color: #373737;
    font-family: "Arial";
}
.customer-box{
    padding-top: 30px;
}
.upper-box{
    background-color: #f0efef;
    padding: 20px;
    border-radius: 10px;
}
.upper-box img{
    width: 30px;
    height: 30px;
}
.upper-box p{
    font-size: 16px;
    line-height: 24px;
    color: #373737;
    font-family: "Arial";
    padding-top: 10px;
}
.down-box{
    padding: 20px 0px;
    display: block;
}
.down-box p:first-child{
    padding: 10px;
    display: inline-block;
    border: 1px solid #eee;
    border-radius: 50%;
}
.down-box p:last-child a{
    font-size: 16px;
    display: inline-block;
    line-height: 24px;
    color: #373737;
    font-family: "Arial";
    padding-top: 5px;

}
.customer-box .latest-title{
    margin-bottom: 30px;
}
.btn-link3{
    background-color: #ab1c26;
    border: none;
}
.explore{
    background-image: url(/static/media/bg_menu2.0e1ab568.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 350px;
    display: flex;
    align-items: center;
}
.explore p {
    font-size: 18px;
line-height: 24px;
color: #ffffff;
}
.explore h2{
    color: #ffffff;
}
.inner-explore{
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner-explore div h2{
    font-size: 40px;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    font-family: Oswald;
    text-align: center;
}
.product-tile p{
    color: #747474;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 10px;
}
.inner-explore div a{
    font-size: 16px;
    border-radius: 45px;
    line-height: 1em;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    background-color: #d4651f;
    display: inline-block;
    padding: 14px 40px;
    text-decoration: none!important;
}
.inner-explore div a:hover{
    color: #fff;
}
.inner-explore div{
    background-color: rgba(0,0,0,0.7);
    display: inline-block;
    padding: 50px;
    text-align: center;
}
.inner-explore div p{
    line-height: 24px;
    width: 80%;
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin:auto;
    padding: 20px 0px;
}
.lalas-box{
    padding: 50px 0px;
    background-color: #f6f2eb;
}
.lalas-box .latest-title{
    margin-bottom: 30px;
}
h4{
        font-size: 40px;
    color: #373737;
    margin-top: 0;
    margin-bottom: 15px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 900;
}
.right-box{
    width: 50%;
}
.nav-bar-icon{
        display: none;
    }
    .right-box img{
        width: 100%;
    }
    .left-content-1 img,.left-content-2 img,.left-content-3 img{
        width: 100%;
    }
    .inner-blog{
        box-shadow: 0 15px 18px rgba(0,0,0,0.05);
        background-color: #fff;
        height: 100%;
    }
    .inner-blog .blog-content{
        padding: 30px;
    }
    .inner-blog .blog-content span{

    color: #a6a6a6;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    text-transform:uppercase;
    display: inline-block;
    margin-bottom: 15px;
    }
    .inner-blog .blog-content .up-text{
        margin-bottom: 10px;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 48px;
    line-height: 1.2em;
    font-weight: bold;
    font-size: 18px;
    color: #636363;
    }
    .blog-link{
        text-decoration: none!important;
    }
     .inner-blog .blog-content .down-text{
      margin-bottom: 0;
    font-size: 15px;
    opacity: 0.65;
    color: #000;
}

    .inner-blog img{
        width: 100%;
        height: 308px;
    }
    .blog-content1{
        position: relative;
    }
    .blog-content1:hover .blog-change{
        opacity: 0.3;
    }
    .blog-content1 .blog-change{
        position: absolute;
        width: 100%;
        height: 98%;
        left: 0;
        top: 0;
        z-index: 2;
        background: #d4651f;
        transition: 0.3s;
        opacity: 0;
        }
        .Proslider1 .left-test-box .inner-testimonial{
            
            padding:40px 35px;
            background-color: #f0efef;
            border-radius: 10px;
            min-height: 179px;
            overflow: hidden;
            max-height: 180px;
            margin-bottom: 10px;
        }
        .Proslider1 .left-test-box .inner-testimonial span p{
            display: inline;
            
        }
        .Proslider1 .left-test-box .inner-testimonial img{
            display: inline-block!important;
            margin-right: 10px;

        }
        .Proslider1 .left-test-box .inner-testimonial p{
        color: #373737 !important;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 24px;
}
.Proslider1 .left-test-box .test-content img {
    border-radius: 50%;
    display: inline-block;
    width: 60px;
    height: 60px;
}
.Proslider1 .left-test-box .test-content span{
    display: block;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    text-transform: uppercase;
    transition: all 0.4s ease 0s;
    font-weight: 600;
    font-style: italic;
    color: #373737;
}
.Proslider1 .left-test-box .test-content p{
        color: #383535;
        text-transform: capitalize;
}
.Proslider1 .left-test-box .test-content{
    text-align: center!important;
}
.testimonial-section{
    padding:50px 0px;
}
.App .Home .testimonial-section .slick-slider ul{
    bottom: -60px;
    height: 30px;
    width: 70px;
    overflow: hidden;
    left: calc(50% - 35px);
}
.App .Home .testimonial-section .slick-slider button{
    background-color: #fff!important;
    border:1px solid #252525;
    border-radius: 50%;
    opacity: 0.8;
    transform: translate(0, -180%);

}
.App .Home .testimonial-section .slick-slider button:after{
    color: #252525;
}

.App .Home .testimonial-section .slick-slider .slick-next:before{
    display: none;
}
.App .Home .testimonial-section .slick-slider .slick-next:after{
    content:"";
    position: absolute;
    background-image:url(/static/media/angle-down-solid1.fcc55fde.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 10px;
    top:  calc(50% - 5px);
    right: calc(50% - 5px);
    transform: rotate(-90deg);


}
.search-tem-list {
    margin-top:50px;
    width: 50%;
    box-shadow: 0 0 5px #777;
    border-radius: 4px;
   
}
.search-tem-list div{
    background-color:#fff;
    padding:5px 10px;
    color:#373737;
    font-size:18px;
    text-transform: capitalize; 
    cursor: pointer;
}
.search-tem-list div:hover{
    border:1px solid #ececec;
    background-color: #eee;
}
.shawls-section .inner-shawls .left-content-1,.shawls-section .inner-shawls .left-content-2,.shawls-section .inner-shawls .left-content-3{
    overflow: hidden;
    height: 255px;
}
.shawls-section .inner-shawls img{
    height: 255px;
}
.left-content-1:hover img,.left-content-2:hover img,.left-content-3:hover img{
    transform: scale(1.3);
    transition: 0.4s;
}
.inner-proslider{
    overflow: hidden;
    border-radius: 50%;
}
.inner-proslider:hover img{
    transform: scale(1.3);
    transition: 0.4s;
}

.App .Home .testimonial-section .slick-slider .slick-prev:before{
    display: none;
}
.App .Home .testimonial-section .slick-slider .slick-prev:after{
   content:"";
    position: absolute;
    background-image:url(/static/media/angle-down-solid1.fcc55fde.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 10px;
    top:  calc(50% - 5px);
    right: calc(50% - 5px);
    transform: rotate(90deg);
}

.lalas-video .left-box h3{
            font-size: 30px;
            font-family: Oswald;
    font-weight: 500;
    line-height: 1.1;
} 
.lalas-video .left-box p{
    font-size: 16px;
    color: #fff;
    opacity: .8;
}
.lalas-video .left-box a{
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    padding: 14px 40px;
    font-size: 16px;
    border-radius: 45px;
    line-height: 1em;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none!important;
    
}   
.footer-column .inner-footer{
    display: flex;
    justify-content: space-between;
} 
.header-right-box ul .nav-icon{
    display: none;
}
.nav-bar-list{
    display: flex;
    justify-content: space-between;
}
.login-form{
    width: 40%!important;
    margin: 0 auto;
}
.login-form1{
    width: 50%;
    margin: 0 auto;
}
.form-title h2{
    font-size: 30px;
    font-weight: bold;
    color: #373737;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: solid 1px #d5d5d5;
    padding-bottom: 14px;
    text-transform: capitalize;
    margin-bottom: 10px;

}
.form-title p{
    font-size: 14px!important;
    font-weight: normal;
    color: #565656;
}
.form-title p span{
    color: #d46630;
}
/* .main-registration-container #register form input{
    margin-bottom: 15px;
} */
.main-registration-container #register form label{
    font-size: 16px;
    margin-top:15px;
    margin-bottom: 5px;
}
.main-registration-container #register form .login-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-registration-container #register form .login-btn a{
    color:#d46630;
    text-decoration: none;
    text-transform: capitalize;
}
.main-registration-container #register form .facebook-box a{
        background: #3d5c98 none repeat scroll 0 0;
    color: #ffffff;
    display: inline-block;
    font-size: 15px;
    padding: 12px 30px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;

}
.main-registration-container #register form .facebook-box{
    text-align: center;
    margin: 30px 0px;
}
.cap-box{
    display: flex;
    justify-content: space-between;
}
.cap-box #cap-it{
    width: 60%;
}
.cap-box #cap-its{
    width: 25%;
}
.ship-box{
    display: flex;
    justify-content: space-between;
}
.ship-box .l-ship-box,.ship-box .r-ship-box{
    width: 100%;
    margin: 0 20px;
}
.product-name{
    display: flex;
}

.login-btn2 button{
    margin-top: 0!important;
}
.cap-box p svg,.login-btn2 p svg{
    font-size: 25px;
}

.cap-box p{
    border:1px solid #ececec;
    padding:3px;
}
.login-btn2 p{
    margin-bottom: 0;
    border:1px solid #ececec;
    padding:5px;
    margin-right: 10px;
}
.address-box{
    margin: 0 20px;
}
#address-content{
    height: 100px;
    margin-bottom: 30px;
}
.main-registration-container #register form .sign-in-box p{
    text-align: center;
    font-size: 14px!important;
    font-weight: normal;
    color: #565656;
}
.main-registration-container #register form .sign-in-box p a{
    color: #e85e00;
    text-decoration: none;
    text-transform: capitalize;
}

.inner-cate .accordion .accordion-item.active .title{
    background-color: #fafafa;
 }
 .inner-cate .accordion .accordion-item.active button,.inner-cate .accordion .accordion-item.active button:after{
     color:#ce9634;
 }
.inner-category-list .innerlist-content{
    display: block;

}
.inner-category-list span svg{
    transform: rotate(90deg);
}

.inner-product img{
    height: 253px;
    width:100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.header-icon li:nth-child(4){
    position: relative;
}
.header-icon li:nth-child(4) span{
    padding: 0 4px;
    background-color: #fff;
    border-radius: 50%;
    color: #000;
    position: absolute;
    right: calc(0% - 5px);
    top: calc(0% - 5px);
    font-size: 12px;
}

.inner-cate .accordion .accordion-item .title:after{
    position: absolute;
    right: calc(0% + 15px);
    top: calc(0% + 7px);
    transform: rotate(-90deg);
    content: '\2304';
    font-size: 20px;
    
}
.inner-cate .accordion .accordion-item.active .title:after{
    content: '\2304';
    transform: rotate(0deg);
    
}
.inner-cate .accordion .accordion-item button{
    color: #3f3f3f;
    text-transform: capitalize;
    font-size: 14px;
    width: 100%;
    
    position: relative;
    background-color: #fafafa;
    line-height: 18px;
    text-decoration: none;
    padding:10px 12px;
    
}
.inner-cate .accordion .accordion-item .panel ul{
    padding: 5px 12px;
}
.inner-cate .accordion .accordion-item .panel p{
    margin-bottom: 5px;
}
.inner-cate .accordion .accordion-item .panel ul a{
    color: #909090;
    display: block;
    padding: 0;
    font-size: 13px;
    line-height: normal;
}

.error {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: #fff;
    width: 100%;
}
h2{
    font-size: 40px;
    color: #c54718;
    font-weight:600;
}
.react-tabs .react-tabs__tab-panel{
    display: flex;
}
.rnc-button{
    position: relative;
}
.rnc-button svg{
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
}
.cap-box{
    margin-bottom: 10px;
}
.Profile .profile-inner-content .react-tabs
 .tab-right-content .react-tabs__tab-panel .login-form
 #register form .login-btn input[type="submit"]{
    background-color: #d46523;
    border: 1px solid #d46523;
    border-radius: 30px;
    color: #fff;
    width: 50%;
    display: inline-block;
    line-height: 1;
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
}
.tab-left-content .react-tabs__tab-list .react-tabs__tab--selected{
    background-color: red;
    color: #fff;
    border-radius: 5px;
}
/*media query*/

@media screen and (max-width: 1200px){
    .navlist li{
        padding: 0 10px;
    }
    .react-tabs__tab-list .react-tabs__tab img{
        width: 100%;
        height: 60px;
    }
    figure{
        height: 100%;
    }
    .tab-content-scroll figure{
        height: 100%;
    }
        .test{
        position: unset;
        top: unset;
        width: unset;
        z-index: unset;
    }
    .checkout-coupon input{
        width: 100%;
    }
    #register form button{
        margin-bottom: 20px;
    }
    .nav-bar ul li a{
        font-size: 12px;
    }
    .footer-column .inner-footer .footy{
        width:25%;
        padding: 0 10px;
    }
    .tab-content-scroll{
        width: 100%;
    }

       .inner-product .quick-view {
        padding: 0 10px;
    }
}
@media screen and (max-width: 992px){
    .inner-product img{
        height: 300px;
    }
    .left-inner-box .inside-blog-content .blog-img{
        padding: 0px 15px;
        margin-bottom:15px;
        width: 100%;


    }
    .left-inner-box{
        margin-right: 0px;
    }
    .left-inner-box .inside-blog-content .blog-details {
        width: 100%;
    }
    .blog-section .inner-blog-box {
        flex-direction: column;
    }
    .blog-section .inner-blog-box .recent-inner-box .recent-blog{
        width: 100%;
        margin-top: 15px;
    }
    .inside-blog-content{
        flex-direction: column;
    }
    .address-content{
        margin-bottom: 30px;
    }
    .Invoice{
        width: 80%;
    }
    .login-form1,.login-form1{
        width: 100%;
    }
    .Collection,.About,.Contact,.Product{
        margin-top: 88px;
    }
        .qty2{
        display: flex;
    }
    .wc-proceed-to-checkout{
        display: unset;
    }
    .pull-right .wc-proceed-to-checkout a{
        margin-right: 20px;
        margin-top: 10px;
    }

    .etabs{
    width: 100%;

}
    .login-form {
        width: 80%!important;
    }
    .pull-right{
        margin-left: 0;
        margin-top: 30px;
    }
    .navlist li{
        padding: 0 5px;
    }
    .wrt-review{
        flex-direction: column;
    }
    .wrt-review div:nth-child(2){
        margin: 10px;
    }
    .header-icon ul li a{
        font-size: 9px;
    }
    .footer-column .inner-footer{
        flex-direction: column;
    }
    .footer-column .inner-footer .footy{
        width: 100%;
        margin-bottom: 15px;
    }
    .newsletter form p:nth-child(1){
        width: 85%;

    }
    .newsletter{
        width: 60%;
    }
    .g-title{
        display: flex!important;
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar ul{
        margin: 0 auto;
    }
      .nav-bar ul li a{
        padding: 0 10px;
    }
    .nav-bar ul li:nth-child(2):before, .nav-bar ul li:nth-child(3):before {
        width: 10px;
        height: 10px;
        right: calc(0% + 0px);
    }
    .left-details-box{
        margin-right: 30px;
    }
    .tab-right-content{
       /* width: 800px; 
        overflow-x: auto; 
        overflow: hidden; 
        white-space: nowrap;*/
    }

}
@media screen and (max-width: 768px){

    .newsletter{
        width: 85%;
    }
    .rnc-row{
        flex-direction: column!important;
    }
    .rnc-column{
        margin-left: 0px!important;
        text-align: center;
    }
    .rnc-column .rnc-button{
        margin-bottom: 0px!important;
    }
    .left-inner-box .inside-blog-content .blog-img{
        height: 100%;
    }
    .left-inner-box .inside-blog-content .blog-img img{
        width: 100%!important;
        height: 100%!important;
    }
    .profile-details-box .right-details-box ul li input{
        width: 100%;
    }
    .outer-blog-box{
        margin-bottom: 20px;
    }
    .show-now{
        display: block!important;
    }
    .hovvy .show-nowit{
        display: block!important;
    }
    .react-tabs__tab-list .react-tabs__tab img{
        width: 100%;
        height: 40px;
    }
    .hovvy1 .show-nowit{
        display: block!important;
    }
    .address-content{
        flex-direction: column;
    }
    .left-address-box .inside-left-box {
        height: 100%;
    }
    .tab-right-content .login-form .form-title,.tab-right-content .login-form #register{
        width: 100%;
    }
    .left-details-box{
        text-align: center;
        margin-right: 0px;
    }
    .right-details-box{
        margin-top: 30px;
    }
   
    .profile-details-box {
        flex-direction: column;
    }
    .mega-menu .tas1, .mega-menu .tas4{
        flex-direction: column;
    }
    .mega-menu .tas4{
        margin-top: 15px;
    }
    .menu-img{
        margin-bottom: 15px;
    }
    .category{
        top: calc(0% + 109px);
        left: calc(0% + 20px);
        width: 50%;
        padding: 0px;
        height: 80%;
        overflow: scroll;
        z-index: 9999999;
    }
    .category1{
        top: calc(0% + 162px);
        left: calc(0% + 20px);
        width: 50%;
        padding: 0px;
        height: 70%;
        overflow: scroll;
        z-index: 9999999;
    }
    .category  .mega-menu .tas4 .menu-img a{
        padding: 0;
    }
    .category1  .mega-menu .tas4 .menu-img a{
        padding: 0;
    }
    .outer-quick-content .quick-content .star-box .q-title{
        margin-bottom: 10px;
    }
    .outer-quick-content .quick-content .pro-details {
        margin-bottom: 10px;
    }
    .s-title{
        margin-bottom: 20px!important;
    }
    .inner-product-content{
        flex-direction: column;
    }
    .inner-product-content{
        margin-top: 20px;
    }
    .quick-content .quick-view1 {
    font-size: 20px;
    top: calc(0% - 5px);
    right: calc(0% + 0px);
    
}   
    .tb-tab-container2{
        display: unset;
    }
    .quick-content {
        padding: 20px;
    }
    /* .react-tabs .react-tabs__tab-panel h2{
        height: 250px!important;
    } */
    .react-tabs .react-tabs__tab-panel h2 img,.react-tabs__tab-panel h2 div {
        height: 250px!important;
        padding:  15px;
    }
    .search-box{
        
        width: 250px;
        left: calc(50% - 125px);
        top: 100px;
    }
    .search-box input{
        background-color: #ececec;
    }

    .header-right-box ul .nav-icon{
        display: block;
        margin-left: 25px;
    }
    .header-right-box ul li a{
        font-size: 15px;
    }
    .nav-bar {
        width: 2%;
    }
    .nav-bar ul li:nth-child(2):before, .nav-bar ul li:nth-child(3):before {
        width: 30px;
        height: 20px;
        top: calc(0% + 10px);
        right: calc(0% + 15px);
    }
    /*
        max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    */
    .nav-bar .nav-bar-list{
        position: fixed;
        width: 55%;
        height: 100vh;
        z-index: 9934;
        top: 0;
        left: -55%;
        flex-direction: column;
        justify-content: flex-start;
        padding: 15px 0px;
        background-color: rgb(0,0,0,0.9);
    }
    .nav-bar .nav-bar-list li a{
        font-size: 15px;
    }
    .nav-bar .nav-bar-list li{
        padding:10px;
        margin-bottom: 15px;
    }
    .Header .logo-box,.Header .header-right-box{
        width: 45%;
    }
    .Header .header-contain{
        max-width: unset!important;
    }
    .navlist{
    position: fixed;
    top: 0;
    left: -50%;
    display: block;
    height: 100vh;
    width: 50%;
    z-index: 3;
    background-color: rgba(0,0,0,0.9);
    }
    .navlist li{
        padding-bottom: 20px;
        position: relative;
    }
    .navlist li:before{
        content: "";
        width: 80%;
        height: 2px;
        position: absolute;
        bottom: 10px;
        left: calc(50% - 40%);
        background-color: #fff;

    }
    .navlist li a{
        font-size: 16px;
    }
    .left-box div h3{
        font-size: 16px;
        line-height: 22px;
    }
    .left-box div p{
        font-size: 13px;
    }
    .buy-btn a:nth-child(1){
        margin: 0px;
    }
     .slider .banner-text,.slider1 .banner-text,.slider2 .banner-text{
        font-size: 20px;
        margin-bottom: 0px;
    }
    .slider h1, .slider2 h1,.slider1 h1{
        font-size: 33px;
        margin-bottom: 0px;
    }
       .lalas-slider,.slider,.slider1,.slider2{
        min-height: 320px!important;
        overflow: hidden;
    }
     .slider .banner-subtext,.slider1 .banner-subtext,.slider2 .banner-subtext{
        font-size: 13px;
    }

    
}
@media screen and (max-width: 767px){
    .lalas-video{
        flex-direction: column;
    }
    .mega-menu .tas1 .tas a{
        font-size: 11px;
        line-height: 21px;
        padding: 5px
    }
    .login-btn2 p{
        margin-bottom: 10px;
    }
    .login-form{
        width: 90%!important;
    }
    .overlay2 input[type=text]{
        width: 65%;
    }
  
  
    .overlay2 button{
        width: 30%;
    }
    .buy-btn a:nth-child(1),.buy-btn a:nth-child(2){
        padding: 4px 20px;
    }
     .login-btn{
        flex-direction:column;
    }
    .design-area{
        padding: 50px 20px;
    }
    .lalas-slider .slick-slider .slick-list{
        height: 320px;
    }
    .footer-bottom .footer-address{
        text-align: center;
    }
    .footer-bottom  .foot-icon{
        padding-top:  15px;
    }
    .lalas-video .left-box{
        width: 100%;
        padding: 30px;
        text-align: center;
    }
    .footer-step{
        flex-direction: column;
        padding: 30px 15px;
    }
    .footer-step .first-line{
        width: 100%;
    }
    .footer-content{
        text-align: left;
    }
    .right-footer-content{
        text-align: left;
        margin-top: 20px;
    }
    .right-footer-content .letter{
        margin-bottom: 20px;
    }
    .copy-content{
        flex-direction: column;
    }
    .copy-content div{
        padding: 5px 0px;
    }
    .right-box{
        width: 100%;
    }
    .shawls-section{
        flex-direction: column;
    }
    .lalas-return-content{
        text-align: left;
        padding-bottom: 30px;
    }
    .banner h1{
        font-size: 36px;
        line-height: 60px;
    }
    .banner-off{
        font-size: 17px;
    }
    .banner{
        min-height: 350px;
    }
    .right-header{
        display: none;
    }
    .inner-explore div p{
        width: 100%;
        padding-bottom: 20px;
    }
    .nav-bar-icon{
        display: block;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .nav-bar-icon svg{
        font-size: 30px;
        color: #ffffff;
    }
    .mystyle{
        left: 0!important;
        transition: 0.4s ;
    }
    .left-test-box{
        margin-bottom: 20px;
    }
    .test-content{
        margin-top: 10px;
    }
    
   
  
   
 
    .inner-explore div h2 {
        font-size: 33px;
        line-height: 40px;
    }
    .category{
        width: 45%;
       }
   .category1{
        width: 45%;
       }
       .test{
        position: unset;
        top: unset;
        width: unset;
        z-index: unset;
    }
    .test-remove{
        position: unset!important;
        
    }

}

.product-price-name:hover {
    color: #d46630;
}

/*@media only screen and (max-width: 540px){
   .star-box .quick-view1{
    top: -410px;
   } 

}
@media only screen and (max-width: 320px){
     .star-box .quick-view1{
    top: -420px;
    right: -2px;
   } 
}*/
.zoomImg {
    will-change: transform;
  }
  
.rnc {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 255px;
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 10px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 50px;
  }
  
  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .rnc-button svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }
  
